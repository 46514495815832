<template>
  <div class="pm">
    <p class="heading">У Вас есть друг, родственник или знакомый <br>которого лишили прав?</p>
    <p class="subheading">Порекомендуйте ему нашу помощь в <strong>возврате прав</strong>!<br>Так Вы поможете другу вернуть права и заработаете деньги!</p>
    <div class="block">
      <div>
        <img src="../assets/img/pm/chatting-app.png" alt="">
        <p>Друга лишают прав</p>
      </div>
      <div>
        <img src="../assets/img/pm/123.png" alt="">
        <p>Рекомендуете ему нашу помощь</p>
      </div>
      <div>
        <img src="../assets/img/pm/banknote.png" alt="">
        <p><strong>Вы получаете свой %</strong></p>
      </div>
      <div>
        <img src="../assets/img/pm/license.png" alt="">
        <p><strong>Возвращаем ему права</strong></p>
      </div>
    </div>
    <div class="work">
      <p>Как это работает</p>
      <ol>
        <li>Среди Ваших знакомых, родственников, друзей наверняка есть человек, которого лишили прав.</li>
        <li>Предложите ему помощь от нашей компании. </li>
        <li>В день заключения договора Вы сразу же получите от 10 до 20% от стоимости договора. Гарантируем анонимность! Никто и никогда не узнает об этом – Вы получите свои деньги на карту в тот же день. </li>
        <li>Мы поможем Вашему другу, родственнику, знакомому с возвратом прав чтобы ему не пришлось и дальше ходить пешком и Вы заработаете на этом. Это идеально!</li>
      </ol>
    </div>
    <div class="help">
      Если возникли вопросы напишите нам в Вотсап <a href="tel:79925258309">+79925258309</a><img src="../assets/img/pm/whatsapp.png" alt=""> и мы пришлем более подробную информацию
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pm'
}
</script>

<style lang="scss" scoped>
  .pm {
    .heading {
      font-size: 36px;
      line-height: 50px;
      opacity: .7;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 100px;
      @media (max-width: 760px) {
        font-size: 24px;
        line-height: 44px;
      }
    }
    .subheading {
      font-size: 26px;
      line-height: 40px;
      opacity: .7;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 760px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .block {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 18px;
      grid-row-gap: 20px;
      margin-bottom: 100px;
      @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-row-gap: 50px;
        margin-bottom: 60px;
      }
      div {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr 1fr;
        justify-items: center;
        align-items: center;
        img {
          max-width: 200px;
          margin: 0 auto;
        }
        p {
          font-size: 30px;
          line-height: 40px;
          text-align: center;
          opacity: .9;
          @media (max-width: 1000px) {
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
    .work {
      margin-bottom: 100px;
      @media (max-width: 550px) {
        margin-bottom: 60px;
      }
      p {
        font-size: 30px;
        opacity: .7;
        margin-bottom: 40px;
        text-align: center;
        text-transform: uppercase;
      }
      ol {
        li {
          font-size: 16px;
          opacity: .9;
          margin: 15px 0;
          line-height: 25px;
        }
      }
    }
  }
  .help {
    font-size: 18px;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      margin-left: 5px;
    }
    a {
      margin-left: 10px;
      &:hover {
        border-radius: 4px;
        background-color: #dcdcdc;
        position: relative;
      }
    }
  }
</style>
